<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">自相关分析结果</h1>
            

            <div class="result-area" id="autocorrelationPart">
                <divider-title name="自相关" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:100px;">滞后阶数</th>
                                                    <th style="width:100px;">自相关系数</th>
                                                    <th style="width:100px;">标准误差</th>
                                                    <th style="width:100px;">Ljung-Box Q</th>
                                                    <th style="width:100px;">Sig</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in autocorrelationPart.lagOrders" :key="key">
                                                    <td>{{toFixedNum(autocorrelationPart.coefficient[key])}}</td>
                                                    <td>{{item}}</td>
                                                    <td>{{toFixedNum(autocorrelationPart.ljungBoxQSig[key])}}</td>
                                                    <td>{{toFixedNum(autocorrelationPart.ljungBoxQStata[key])}}</td>
                                                    <td>{{toFixedNum(autocorrelationPart.stdError[key])}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="zxgtx">
                <divider-title name="自相关图形" />
                <chart :chartData="acMixChart" />
            </div>

            <div class="result-area" id="partialAutocorrelationPart">
                <divider-title name="偏自相关" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:100px;">滞后阶数</th>
                                                    <th style="width:100px;">偏自相关系数</th>
                                                    <th style="width:100px;">标准误差</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in partialAutocorrelationPart.lagOrders" :key="key">
                                                    <td>{{item}}</td>
                                                    <td>{{toFixedNum(partialAutocorrelationPart.partialCoefficient[key])}}</td>
                                                    <td>{{toFixedNum(partialAutocorrelationPart.stdError[key])}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="pzxgtx">
                <divider-title name="偏自相关图形" />
                <chart :chartData="pacMixChart" />
            </div>

        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
import Chart from './components/Chart.vue';
export default {
    components:{DividerTitle,Chart},
    computed:{
        result() {
            let data = getSessionItem('analysis_autocorre');
            return data.analysis_autocorre;
        },
        parts() {
            let arr = [
                {name: '自相关', keys: ['autocorrelationPart']},
                {name: '自相关图形', keys: ['zxgtx']},
                {name: '偏自相关', keys: ['partialAutocorrelationPart']},
                {name: '偏自相关图形', keys: ['pzxgtx']}
            ]
            return arr;
        },
        testData() {
            if(this.result) {
                return this.result.testData.data;
            } else {
                return null;
            }
        },
        autocorrelationPart() {
            if(this.result&&this.result.autocorrelationBO) {
                return this.result.autocorrelationBO.autocorrelationPart;
            } else {
                return null;
            }
        },
        partialAutocorrelationPart() {
            if(this.result&&this.result.autocorrelationBO) {
                return this.result.autocorrelationBO.partialAutocorrelationPart;
            } else {
                return null;
            }
        },
        acMixChart() {
            if(this.result&&this.result.acMixChart) {
                this.result.acMixChart.series.forEach(item=>{
                    if(item.type==='bar') {
                        item.barMaxWidth = 30
                    }
                })
                return this.result.acMixChart;
            } else {
                return null;
            }
        },
        pacMixChart() {
            if(this.result&&this.result.pacMixChart) {
                this.result.pacMixChart.series.forEach(item=>{
                    if(item.type==='bar') {
                        item.barMaxWidth = 30
                    }
                })
                return this.result.pacMixChart;
            } else {
                return null;
            }
        }
        

        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            if(num) {
                return num.toFixed(4);
            }else {
                return '-';
            }
        },
    }
}
</script>